<template>
  <div class="mainContainerPath d-flex mb-2 align-items-center">
    <div class="arrow d-flex align-items-center">
      <img src="../../assets/icons/arrow.png" alt="go to back" />
    </div>
    <div class="pathname d-flex align-items-center">
      <a class="text-transform:uppercase;" @click="$router.go(-1)"
        ><span style="font-size: 14px; font-family: mtavruli">{{
          $t("front.back")
        }}</span></a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.mainContainerPath {
  display: flex;
  max-width: 1400px;
  width: 100%;
  margin: auto;
}
.mainContainer img {
  width: 30px;
}

.arrow img {
  width: 14px;
  padding-bottom: 2px;
}
.pathname p {
  margin-bottom: 0px;
  color: #4d4d4c;
}
.pathname {
  display: flex;
  text-align: center;
  align-items: center;
}
.pathname a {
  cursor: pointer;
  color: #4d4d4c;
  font-family: mtavruli;
}
</style>
